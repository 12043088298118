/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import OpenDialogButton from "../../../components/Dialog/OpenDialogButton";
import { isEqual } from "lodash";
import * as React from "react";
import AreaTitle from "components/AreaTitle";
import { repository } from "clientInstance";
import { TenantResource, ProjectResource, EnvironmentResource, ProjectSummaryResource } from "client/resources";
import TenantCard from "../TenantCard/TenantCard";
import { FilterTextChip, EnvironmentChip, ProjectChip } from "../../../components/Chips/index";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import PermissionCheck, { hasPermission } from "../../../components/PermissionCheck/PermissionCheck";
import { Permission } from "../../../client/resources/permission";
import { ActionButtonType } from "../../../components/Button/ActionButton";
import AddTenantDialog from "areas/tenants/Tenants/AddTenantDialog";
import PaperLayout from "components/PaperLayout/PaperLayout";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import { RouteComponentProps, withRouter } from "react-router";
import Onboarding from "./Onboarding";
import * as tenantTagsets from "components/tenantTagsets";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import { TagIndex } from "components/tenantTagsets";
import Tag from "components/Tag/Tag";
import MobileDetect from "mobile-detect";
import routeLinks from "../../../routeLinks";
import URI from "urijs";
import { AdvancedTenantTagsSelector } from "../../../components/AdvancedTenantSelector/AdvancedTenantSelector";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import LoadMoreCard from "components/LoadMoreCard/LoadMoreCard";
import { Select } from "components/form";
import DrawerWrapperLayout from "components/Drawer/DrawerWrapperLayout";
import InternalRedirect from "components/Navigation/InternalRedirect";
import FormPage from "components/FormPage/FormPage";
import EnvironmentSelect from "components/form/EnvironmentSelect/EnvironmentSelect";
const styles = require("./style.less");

type TenantsProps = {
    initialData: InitialData;
} & RouteComponentProps;

interface TenantsState extends DataBaseComponentState {
    tenants: TenantResource[];
    missingVariables: { [tenantId: string]: boolean };
    searchTagMatches: string[];
    openTenantFilters: boolean;
    filter: TenantsFilter;
    currentTakeSize: number;
    projectSummaries: ProjectSummaryResource[];
    environments: EnvironmentResource[];
    redirectTo?: string;
}

const noProjectSelected = undefined;
type NoProjectSelected = typeof noProjectSelected;

const noEnvironmentSelected = undefined;
type NoEnvironmentSelected = typeof noEnvironmentSelected;

interface TenantsFilter {
    searchText: string;
    selectedTags: string[];
    environmentId: string | NoEnvironmentSelected;
    projectId: string | NoProjectSelected;
}

class FilterLayout extends AdvancedFilterLayout<TenantsFilter> {}

interface InitialData {
    tenants: TenantResource[];
    tagIndex: TagIndex;
}

const getSearchTagMatches = async (selectedTags: string[]) => {
    const searchTags = selectedTags.length === 0 ? null : await repository.Tenants.tagTest([], selectedTags);

    let searchTagMatches: string[] = [];
    if (searchTags != null) {
        searchTagMatches = Object.keys(searchTags).filter((tenantId) => searchTags[tenantId].IsMatched);
    }

    return searchTagMatches;
};

const TenantsFormPage = FormPage<InitialData>();
const title = "Tenants";

const TenantsPage: React.FC = () => {
    return (
        <TenantsFormPage
            title={title}
            load={async () => {
                const getTenants = repository.Tenants.all();
                const getTagIndex = tenantTagsets.getTagIndex();

                const [tenants, tagIndex] = await Promise.all([getTenants, getTagIndex]);

                return {
                    tenants,
                    tagIndex,
                };
            }}
            renderWhenLoaded={(data) => {
                return <Tenants initialData={data} />;
            }}
        />
    );
};

//eslint-disable-next-line react/no-unsafe
class TenantsInternal extends DataBaseComponent<TenantsProps, TenantsState> {
    private pagingSize: number = 100;

    constructor(props: TenantsProps) {
        super(props);
        this.state = {
            tenants: this.props.initialData.tenants,
            openTenantFilters: false,
            searchTagMatches: [],
            filter: createDefaultFilter(),
            missingVariables: {},
            currentTakeSize: this.pagingSize,
            projectSummaries: [],
            environments: [],
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const filter = this.filtersFromQueryString(this.props, this.state);
            const searchTagMatches = await getSearchTagMatches(filter.selectedTags);

            this.setState({
                filter,
                searchTagMatches,
            });

            // @Performance: Missing variables is a known bottleneck at scale. Run this in the background after we've setState
            // for tenants/tags above (so we don't block UI while waiting for this response).
            const missingVariables = await this.getMissingVariableIndex();
            this.setState({ missingVariables });
        });
    }

    async loadFilterLookupData() {
        await this.doBusyTask(async () => {
            const getProjectSummaries = hasPermission(Permission.ProjectView) ? repository.Projects.summaries() : Promise.resolve<ProjectResource[]>([]);
            const getEnvironments = hasPermission(Permission.EnvironmentView) ? repository.Environments.all() : Promise.resolve<EnvironmentResource[]>([]);

            const [projectSummaries, environments] = await Promise.all([getProjectSummaries, getEnvironments]);

            this.setState({
                projectSummaries,
                environments,
            });
        });
    }

    async getMissingVariableIndex(): Promise<{ [tenantId: string]: boolean }> {
        const missingVariables = await repository.Tenants.missingVariables();
        return missingVariables.reduce((idx: any, missing) => {
            idx[missing.TenantId] = true;
            return idx;
        }, {});
    }

    async onUpdateFilter(filter: TenantsFilter) {
        const tenantsFilterChanged = !isEqual(filter.selectedTags, this.state.filter.selectedTags) || !isEqual(filter.projectId, this.state.filter.projectId) || !isEqual(filter.environmentId, this.state.filter.environmentId);
        if (tenantsFilterChanged) {
            this.setState({ searchTagMatches: await getSearchTagMatches(filter.selectedTags) });
        }

        this.setState({ filter }, () => this.queryStringFromFilters());
    }

    filtersFromQueryString = (props: TenantsProps, state: TenantsState): TenantsFilter => {
        const original = new URI().search(props.location.search).search(true);
        return {
            ...state.filter,
            searchText: original.search || "",
            selectedTags: ensureArray(original.tags) || [],
            environmentId: original.environment || noEnvironmentSelected,
            projectId: original.project || noProjectSelected,
        };
    };

    UNSAFE_componentWillReceiveProps(props: TenantsProps) {
        const newFilter = this.filtersFromQueryString(props, this.state);
        if (!isEqual(newFilter, this.state.filter)) {
            this.setState({ filter: newFilter });
        }
    }

    queryStringFromFilters = (): void => {
        const filter = this.state.filter;
        const search: any = {};
        if (filter.selectedTags.length > 0) {
            search.tags = filter.selectedTags;
        }
        if (filter.searchText !== "") {
            search.search = filter.searchText;
        }
        if (filter.projectId !== noProjectSelected) {
            search.project = filter.projectId;
        }
        if (filter.environmentId !== noEnvironmentSelected) {
            search.environment = filter.environmentId;
        }

        const newQS = new URI().search(search).search();

        if (this.props.location.search !== newQS) {
            const location = { ...this.props.history, search: newQS };
            this.props.history.replace(location);
        }
    };

    filteredTenants = (tenant: TenantResource) => {
        const filter = this.state.filter;
        const searchTagMatches = this.state.searchTagMatches;

        const searchTextMatches = filter.searchText === "" || (filter.searchText !== "" && tenant.Name.toLowerCase().includes(filter.searchText.toLowerCase()));
        const tenantTagMatches = searchTagMatches.length === 0 || (!!searchTagMatches && searchTagMatches.indexOf(tenant.Id) !== -1);

        const environmentFilter = filter.environmentId;
        const environmentMatches = environmentFilter === noEnvironmentSelected || Object.keys(tenant.ProjectEnvironments).filter((projectId) => tenant.ProjectEnvironments[projectId].includes(environmentFilter)).length > 0;
        const projectMatches = filter.projectId === noProjectSelected || Object.keys(tenant.ProjectEnvironments).includes(filter.projectId);

        return searchTextMatches && tenantTagMatches && environmentMatches && projectMatches;
    };

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const AddTenant = (label: string) => (
            <PermissionCheck permission={Permission.TenantCreate}>
                <OpenDialogButton type={ActionButtonType.Primary} label={label}>
                    <AddTenantDialog title="Add New Tenant" tenantCreated={this.onTenantCreated} />
                </OpenDialogButton>
            </PermissionCheck>
        );

        const filterChanged = !isEqual(this.state.filter, createDefaultFilter());
        const matchCount = filterChanged ? this.state.tenants.filter(this.filteredTenants).length : 0;
        const filterResult = filterChanged ? { numberOfMatches: matchCount, singleText: "tenant", pluralText: "tenants" } : undefined;
        // Disable autoFocus filtering for mobile (Android has issues and is annoying users).
        const md = new MobileDetect(window.navigator.userAgent);
        const autoFocus = md.isPhoneSized() ? false : true;
        return (
            <main id="maincontent">
                <AreaTitle link={routeLinks.tenants} title="Tenants">
                    <div className={styles.actionContainer}>{AddTenant("Add Tenant")}</div>
                </AreaTitle>
                <DrawerWrapperLayout>
                    <PaperLayout title={null} busy={this.state.busy} errors={this.errors} fullWidth={true} flatStyle={true}>
                        {this.state.tenants.length === 0 && <Onboarding />}
                        {this.state.tenants.length > 0 && (
                            <TransitionAnimation>
                                <FilterLayout
                                    filter={this.state.filter}
                                    defaultFilter={createDefaultFilter()}
                                    hideDivider={true}
                                    filterSections={[
                                        {
                                            render: (
                                                <>
                                                    <PermissionCheck permission={Permission.ProjectView} wildcard={true}>
                                                        <Select
                                                            value={this.state.filter.projectId}
                                                            onChange={(projectId) => this.onUpdateFilter({ ...this.state.filter, projectId })}
                                                            items={this.state.projectSummaries.map((p) => ({ value: p.Id, text: p.Name }))}
                                                            allowClear={true}
                                                            allowFilter={true}
                                                            fieldName="project"
                                                        />
                                                    </PermissionCheck>
                                                    <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                                                        <EnvironmentSelect
                                                            value={this.state.filter.environmentId}
                                                            onChange={(environmentId) => this.onUpdateFilter({ ...this.state.filter, environmentId })}
                                                            environments={this.state.environments}
                                                            allowClear={true}
                                                            allowFilter={true}
                                                            fieldName="environment"
                                                        />
                                                    </PermissionCheck>
                                                    <AdvancedTenantTagsSelector
                                                        selectedTenantTags={this.state.filter.selectedTags}
                                                        onChange={(selectedTags) => this.onUpdateFilter({ ...this.state.filter, selectedTags })}
                                                        doBusyTask={this.doBusyTask}
                                                        showPreviewButton={false}
                                                    />
                                                </>
                                            ),
                                        },
                                    ]}
                                    additionalHeaderFilters={[
                                        <FilterSearchBox placeholder="Filter by name..." value={this.state.filter.searchText} autoFocus={autoFocus} onChange={(searchText) => this.onUpdateFilter({ ...this.state.filter, searchText })} />,
                                    ]}
                                    onFilterReset={(filter) => this.onUpdateFilter(filter)}
                                    filterByChips={this.filterByChips()}
                                    filterResult={filterResult as any}
                                    renderContent={() => this.renderCards()}
                                    onToggleFilter={async (isOpen: boolean) => {
                                        if (isOpen) {
                                            await this.loadFilterLookupData();
                                        }
                                    }}
                                />
                            </TransitionAnimation>
                        )}
                    </PaperLayout>
                </DrawerWrapperLayout>
            </main>
        );
    }

    private onTenantCreated = (tenant: TenantResource) => {
        this.setState({ redirectTo: routeLinks.tenant(tenant.Id).root });
    };

    private renderCards() {
        const tenants = this.state.tenants;
        const filteredTenants = tenants.filter(this.filteredTenants);
        const filteredTenantsPage = filteredTenants.slice(0, this.state.currentTakeSize);
        const cards = filteredTenantsPage.map((tenant, k) => <TenantCard key={tenant.Id} tenant={tenant} hasMissingVariables={this.state.missingVariables[tenant.Id]} tagIndex={this.props.initialData.tagIndex} />);
        const showLoadMoreAction = this.state.currentTakeSize < filteredTenants.length;
        if (showLoadMoreAction) {
            cards.push(
                <LoadMoreCard
                    key="lm"
                    onLoadMore={() => {
                        const newTakeSize = this.state.currentTakeSize + this.pagingSize;
                        this.setState({ currentTakeSize: newTakeSize });
                    }}
                    onLoadAll={() => {
                        const newTakeSize = repository.takeAll;
                        this.setState({ currentTakeSize: newTakeSize });
                    }}
                />
            );
        }

        return (
            <div className={styles.cardListContainer}>
                {showLoadMoreAction && <div className={styles.loadMoreHeaderInfo}>{`Showing ${this.state.currentTakeSize} of ${filteredTenants.length} tenants.`}</div>}
                <div className={styles.cardList}>{cards}</div>
            </div>
        );
    }

    private filterByChips() {
        const groupedTagsets = tenantTagsets.groupByTagSet(this.state.filter.selectedTags);
        const tagsFilterByChips = groupedTagsets.map((group, index) => {
            return (
                <div key={index}>
                    {group.tags
                        .map((t) => this.props.initialData.tagIndex[t])
                        .map((t) => (
                            <Tag tagName={t.Name} description={t.Description} tagColor={t.Color} key={t.Id} small={false} />
                        ))}
                </div>
            );
        });

        const nameSearchChip = this.state.filter.searchText === "" ? null : <FilterTextChip key={"search-text"} filterText={this.state.filter.searchText} />;
        const selectedFilterEnvironment = this.state.filter.environmentId === noEnvironmentSelected ? undefined : this.state.environments.find((x) => x.Id === this.state.filter.environmentId);
        const selectedFilterProject = this.state.filter.projectId === noProjectSelected ? undefined : this.state.projectSummaries.find((x) => x.Id === this.state.filter.projectId);

        const environmentChip = selectedFilterEnvironment === undefined ? null : <EnvironmentChip key={"search-environment"} environmentName={selectedFilterEnvironment.Name} />;
        const projectChip = selectedFilterProject === undefined ? null : <ProjectChip key={"search-project"} project={selectedFilterProject} />;

        return (tagsFilterByChips.length || !!nameSearchChip || !!environmentChip || !!projectChip) && [nameSearchChip, tagsFilterByChips, environmentChip, projectChip];
    }
}

function createDefaultFilter(): TenantsFilter {
    return {
        searchText: "",
        selectedTags: [],
        environmentId: noEnvironmentSelected,
        projectId: noProjectSelected,
    };
}

function ensureArray(obj: any) {
    if (!obj) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj;
    }
    return [obj];
}

const Tenants = withRouter(TenantsInternal);

export default TenantsPage;
