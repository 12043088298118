import routeLinks from "routeLinks";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import MissingVariablesIcon from "../../../tenants/MissingVariablesIcon/MissingVariablesIcon";
import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import BusyRefreshIndicator from "components/BusyRefreshIndicator";
const styles = require("./style.less");

interface TenantsMissingVariablesNotifierProps {
    missingVariableTenantsPromise: Promise<string[]>;
    rowId: string;
}

interface TenantsMissingVariablesNotifierState extends DataBaseComponentState {
    tenantsMissingVariables: string[];
}

class TenantsMissingVariablesNotifier extends DataBaseComponent<TenantsMissingVariablesNotifierProps, TenantsMissingVariablesNotifierState> {
    constructor(props: TenantsMissingVariablesNotifierProps) {
        super(props);
        this.state = {
            tenantsMissingVariables: [],
        };
    }

    componentDidMount = async () => {
        await this.doBusyTask(async () => {
            const tenantsMissingVariables = await this.props.missingVariableTenantsPromise;
            this.setState({ tenantsMissingVariables });
        });
    };

    render = () => {
        const { rowId } = this.props;
        const { tenantsMissingVariables, busy } = this.state;
        const hasMissingVariable = tenantsMissingVariables.indexOf(rowId) !== -1;

        return busy ? (
            <BusyRefreshIndicator show={true} />
        ) : (
            hasMissingVariable && (
                <div className={styles.variableAlert}>
                    <InternalLink to={routeLinks.tenant(rowId).variables()}>
                        <MissingVariablesIcon show={true} />
                    </InternalLink>
                </div>
            )
        );
    };

    reload = async () => {
        await this.doBusyTask(async () => {
            const tenantsMissingVariables = await this.props.missingVariableTenantsPromise;
            this.setState({ tenantsMissingVariables });
        });
    };
}

export default TenantsMissingVariablesNotifier;
