/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { repository } from "clientInstance";
import { DashboardResource, ProjectResource, ReleaseResource, DeploymentTemplateResource, TenantedDeploymentMode, Permission } from "client/resources";
import DashboardDataCube from "./DashboardDataCube";
import ProgressionDataCube from "./ProgressionDataCube";
import { DashboardFilters, DataCube, DimensionTypes } from "./DataCube";
import * as tenantTagsets from "components/tenantTagsets";
import { DataBaseComponent, DataBaseComponentState, Refresh } from "components/DataBaseComponent/DataBaseComponent";
import { ChannelResource } from "../../../../client/resources/channelResource";
import { LifecycleResource } from "../../../../client/resources/lifecycleResource";
import { DashboardFilter } from "client/repositories/dashboardRepository";
import { uniq } from "lodash";
import { OnboardingState, onboardingStateUpdated } from "components/GettingStarted/reducers/onboardingArea";
import { connect } from "react-redux";
import { DashboardRenderMode } from "client/resources/performanceConfigurationResource";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { Dispatch, Action } from "redux";
import { Errors } from "../../../../components/DataBaseComponent";

export type RenderDashboardProps = DashboardDataSourceState & { errors: Errors | undefined };

interface DashboardDataSourceProps {
    project?: ProjectResource;
    filters: DashboardFilters;
    dashboardRenderMode?: DashboardRenderMode; //TODO - this prop was added so that it forces a rerender of the menu item, probably find another way to fix this
    render(props: RenderDashboardProps): JSX.Element;
}

interface GlobalConnectedProps {
    showFooterOnDashboard?: boolean;
}

interface GlobalDispatchProps {
    onOnboardingStateUpdated?(onboardingState: OnboardingState): void;
}

type DashboardDataSourceConnectedProps = DashboardDataSourceProps & GlobalConnectedProps & GlobalDispatchProps;

type DashboardDataSourceState = DataBaseComponentState & {
    projectLimit?: number;
    cube?: DataCube;
    hasInitialLoaded: boolean;
};

const refreshIntervalInMs = 6000;

//eslint-disable-next-line react/no-unsafe
class DashboardDataSourceInternal extends DataBaseComponent<DashboardDataSourceConnectedProps, DashboardDataSourceState> {
    private refreshFunction: () => Promise<DataCube> = undefined!;
    private project: ProjectResource | undefined;
    private filters: DashboardFilters = undefined!;
    private refresh: Refresh = undefined!;

    constructor(props: DashboardDataSourceConnectedProps) {
        super(props);
        this.state = {
            hasInitialLoaded: false,
        };
    }

    async componentDidMount() {
        this.project = this.props.project;
        this.filters = this.props.filters;
        await this.doBusyTask(async () => {
            this.setupDashboard();
            this.refresh = await this.startRefreshLoop(this.refreshCube, refreshIntervalInMs, true);
            this.setState({ hasInitialLoaded: true });
        });
    }

    async UNSAFE_componentWillReceiveProps(nextProps: DashboardDataSourceProps) {
        let shouldRefresh = false;

        if (nextProps.filters !== this.props.filters) {
            shouldRefresh = true;
        }

        if (nextProps.project !== this.props.project) {
            shouldRefresh = true;
        }

        if (!shouldRefresh) {
            return;
        }

        this.project = nextProps.project;
        this.filters = nextProps.filters;

        await this.doBusyTask(async () => {
            this.setupDashboard();
            if (this.refresh) {
                await this.refresh();
                this.refreshOnboardingState();
            }
        });
    }

    render() {
        return this.props.render({ ...this.state, errors: this.errors });
    }

    private refreshOnboardingState() {
        const onboardingState = {
            showFooterOnDashboard: false,
        };
        onboardingState.showFooterOnDashboard = dashboardDataHasProjects(this.state);
        this.props.onOnboardingStateUpdated!(onboardingState);
    }

    private loadDashboardData(releaseId?: string): Promise<DashboardResource> {
        const args: DashboardFilter = this.project ? { projectId: this.project.Id, showAll: true } : { highestLatestVersionPerProjectAndEnvironment: true };

        if (releaseId) {
            args.releaseId = releaseId;
        }

        return repository.Dashboards.getDashboard(args);
    }

    private loadProgressionData() {
        return repository.Progression.getProgression(this.project!);
    }

    private loadChannels() {
        return repository.Channels.allFromProject(this.project!);
    }

    private async loadReleases(releaseId?: string): Promise<ReleaseResource[]> {
        if (releaseId) {
            return Promise.resolve([await repository.Releases.get(releaseId)]);
        }

        const result = await repository.Projects.getReleases(this.project!);
        return result.Items;
    }

    private filteredRelease() {
        if (this.filters[DimensionTypes.Release]) {
            return Object.keys(this.filters[DimensionTypes.Release])[0];
        }
        return null;
    }

    private async loadPromotionData(release: ReleaseResource): Promise<DeploymentTemplateResource> {
        return repository.Releases.getDeploymentTemplate(release);
    }

    private async loadLifecycles(channelsAsync: Promise<ChannelResource[]>): Promise<LifecycleResource[]> {
        const channels = await channelsAsync;
        const lifecycleIds = channels.map((channel) => channel.LifecycleId);
        const lifecycles = await repository.Lifecycles.allById(lifecycleIds);
        return Object.keys(lifecycles).map((id) => lifecycles[id]);
    }

    private async listTenantsWithMissingVariables(): Promise<string[]> {
        const hasLibraryVariableSetView = isAllowed({ permission: Permission.LibraryVariableSetView, environment: "*", tenant: "*" });

        if (hasLibraryVariableSetView) {
            const missingVariables = await repository.Tenants.missingVariables({ projectId: this.project!.Id }, false);
            return missingVariables.map((mv) => mv.TenantId);
        }
        return Promise.resolve([]);
    }

    private async mainDashboardRefresh(): Promise<DataCube> {
        const data = await this.loadDashboardData();
        this.setState({ projectLimit: data.ProjectLimit || 200 });
        return new DashboardDataCube(data, data.Tenants, [], [], null!, [], [], Promise.resolve([]));
    }

    private async tenantedProjectDashboardRefresh() {
        if (this.project?.IsVersionControlled) {
            return this.tenantedProjectDashboardFromProgressionDataRefresh();
        }

        // The below method shouldn't be needed.
        //   The only reason we're not using the above for both db and git flavoured projects is because there's still a questionmark
        //   about the perf if we use our progression endpoint to get progressions for tenanted projects.
        //   We already rely on the progression endpoint for non-tenanted Git projects.
        // If there ARE perf issues, we'll have to fix them eventually, since we can't use the below "from scratch" method for Git projects.
        //   (owing to the fact we can't query "all channels" for a project whose channels are spread across multiple branches in a Git repo
        //    ...unless we know which branches to go looking for ...which happens to be what our friend the progression endpoint does for us).
        // TODO: @team-config-as-code remove the below method and make all projects use the above once we've investigated and fixed any perf issues.
        return this.tenantedProjectDashboardFromScratchRefresh();
    }

    private async tenantedProjectDashboardFromProgressionDataRefresh(): Promise<DataCube> {
        const progressionData = await this.projectDashboardRefresh();

        const channels = Object.values(progressionData.channelIndex);
        const lifecycles = Object.values(progressionData.lifecycleIndex);
        const releases = Object.values(progressionData.releaseIndex);

        const releaseId = this.filteredRelease();
        const [data, promotions, tagSets] = await Promise.all([this.loadDashboardData(releaseId!), releaseId ? this.loadPromotionData(releases[0]) : Promise.resolve(null), tenantTagsets.getAll()]);

        const missingVariableTenantsPromise = this.listTenantsWithMissingVariables();

        return new DashboardDataCube(data!, data!.Tenants, releases, channels!, promotions!, tagSets!, lifecycles!, missingVariableTenantsPromise, this.project, releaseId!);
    }

    private async tenantedProjectDashboardFromScratchRefresh(): Promise<DataCube> {
        const releaseId = this.filteredRelease();
        const releases = await this.loadReleases(releaseId!);
        const channelsLoader = this.loadChannels();
        const [data, channels, promotions, tagSets, lifecycles] = await Promise.all([
            this.loadDashboardData(releaseId!),
            channelsLoader,
            releaseId ? this.loadPromotionData(releases[0]) : Promise.resolve(null),
            tenantTagsets.getAll(),
            this.loadLifecycles(channelsLoader),
        ]);

        const missingVariableTenantsPromise = this.listTenantsWithMissingVariables();

        return new DashboardDataCube(data!, data!.Tenants, releases, channels!, promotions!, tagSets!, lifecycles!, missingVariableTenantsPromise, this.project, releaseId!);
    }

    private async projectDashboardRefresh(): Promise<DataCube> {
        const data = await this.loadProgressionData();
        const progressionData = new ProgressionDataCube(data, this.project!);

        if (!this.project?.IsVersionControlled) {
            // If the project is not version controlled, we also want to show
            // channels with no releases (because we can and we always have).
            // Fetch all the channels for the project and add them to the
            // list if they're not already there.
            const allChannels = await this.loadChannels();
            progressionData.addAdditionalChannels(allChannels);
        }

        return progressionData;
    }

    private setupDashboard() {
        if (!this.project) {
            this.refreshFunction = this.mainDashboardRefresh;
        } else if (this.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted) {
            this.refreshFunction = this.tenantedProjectDashboardRefresh;
        } else {
            this.refreshFunction = this.projectDashboardRefresh;
        }
    }

    private refreshCube = async () => {
        return { cube: await this.refreshFunction() };
    };
}

const mapGlobalStateToProps = (state: GlobalState) => {
    const currentOnboardingState = !state.onboardingArea.config
        ? {}
        : {
              showFooterOnDashboard: state.onboardingArea.config.showFooterOnDashboard,
          };
    return currentOnboardingState;
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => {
    return {
        onOnboardingStateUpdated: (onboardingState: OnboardingState) => {
            dispatch(
                onboardingStateUpdated({
                    showFooterOnDashboard: onboardingState.showFooterOnDashboard,
                })
            );
        },
    };
};

const DashboardDataSource = connect<{}, GlobalDispatchProps, DashboardDataSourceConnectedProps, GlobalState>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(DashboardDataSourceInternal);

export default DashboardDataSource;

export { DashboardDataSourceState };

export function hasReachedMinimumThresholdForHidingOnboardingOnDashboard(dataSourceState: DashboardDataSourceState): boolean {
    return dataSourceState.hasInitialLoaded && dataSourceState.cube ? Object.keys(dataSourceState.cube.projectIndex).length === 0 : false;
}

export function dashboardDataHasProjects(dataSourceState: DashboardDataSourceState): boolean {
    return dataSourceState.hasInitialLoaded && dataSourceState.cube ? Object.keys(dataSourceState.cube.projectIndex).length > 0 : false;
}

export function dashboardDataHasEnvironments(dataSourceState: DashboardDataSourceState): boolean {
    return dataSourceState.hasInitialLoaded && dataSourceState.cube ? Object.keys(dataSourceState.cube.environmentIndex).length > 0 : false;
}
