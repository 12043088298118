import * as React from "react";
import * as tenantTagsets from "components/tenantTagsets";
import type { DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "components/DataBaseComponent/DataBaseComponent";
import type { EnvironmentsSummaryResource, TagSetResource, TenantResource, WorkerPoolsSummaryResource } from "client/resources";
import FormPage from "components/FormPage/FormPage";
import InfrastructureLayout from "../InfrastructureLayout";
import MachineIconHelper from "utils/MachineIconHelper";
import Onboarding from "./Onboarding";
import PaperLayout from "components/PaperLayout/PaperLayout";
import Permission from "../../../../client/resources/permission";
import { connect } from "react-redux";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { repository } from "clientInstance";
import routeLinks from "routeLinks";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
import { EnvironmentsContent } from "./EnvironmentsCard/EnvironmentsContent";
import { InfraOverviewCard } from "./InfraOverviewCard";
import { MachinesContent } from "./MachinesCard/MachinesContent";
import { HealthStatusContent } from "./HealthStatusCard/HealthStatusContent";
import { sum } from "lodash";
import { RolesContent } from "./RolesCard/RolesContent";
import { WorkerPoolsContent } from "./WorkerPoolsCard/WorkerPoolsContent";
import { TenantsContent } from "./TenantsCard/TenantsContent";
import { TagSetsContent } from "./TagSetsCard/TagSetsContent";
import { InfraOverviewOnboardingActions } from "./InfraOverviewOnboardingActions";

const styles = require("./style.less");

interface InfrastructureDashboardState extends DataBaseComponentState {
    filterPartialName: string;
    isSearching: boolean;
}

type TenantResourceGroup = {
    environmentsSummaryResource: EnvironmentsSummaryResource | undefined;
    tenantResources: TenantResource[];
};

type TagSetsResourceGroup = {
    environmentsSummaryResource: EnvironmentsSummaryResource | undefined;
    tagSetsResources: TagSetResource[];
};

interface InitialData {
    environmentsSummaryPromise: Promise<EnvironmentsSummaryResource> | undefined;
    workerpoolsSummaryPromise: Promise<WorkerPoolsSummaryResource> | undefined;
    machineRolesPromise: Promise<string[]> | undefined;
    tenantResourceGroupPromises: Promise<TenantResourceGroup>;
    tagSetsResourceGroupPromises: Promise<TagSetsResourceGroup>;
    showOnboarding: boolean;
}

interface InfrastructureDashboardProps {
    initialData: InitialData;
}

interface GlobalConnectedProps extends InfrastructureDashboardProps {
    isMultiTenancyEnabled: boolean;
}

const InfrastructureDashboardFormPage = FormPage<InitialData>();
const Title = "Overview";
const maxNumberOfItemsToShowInCards = 50;

const InfrastructureDashboardPage: React.FC = () => {
    return (
        <InfrastructureDashboardFormPage
            title={Title}
            load={async () => {
                const environmentsSummaryPromise = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? repository.Environments.summary() : undefined;
                const workerpoolsSummaryPromise = isAllowed({ permission: Permission.WorkerView }) ? repository.WorkerPools.summary() : undefined;

                const machineRolesPromise = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? repository.MachineRoles.all() : undefined;

                const environmentOnboarding = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) && (await repository.Environments.list({ take: 0 }).then((v) => v.TotalResults === 0));
                const workersOnboarding = isAllowed({ permission: Permission.WorkerView }) && (await repository.Workers.list({ take: 0 }).then((v) => v.TotalResults === 0));
                const showOnboarding = environmentOnboarding && workersOnboarding;

                const tenantResourceGroupPromises = (async function loadTenantsResources() {
                    return {
                        tenantResources: await repository.Tenants.all(),
                        environmentsSummaryResource: await environmentsSummaryPromise,
                    };
                })();

                const tagSetsResourceGroupPromises = (async function loadTagSetsPromise() {
                    return {
                        tagSetsResources: await tenantTagsets.getAll(),
                        environmentsSummaryResource: await environmentsSummaryPromise,
                    };
                })();

                return {
                    environmentsSummaryPromise,
                    workerpoolsSummaryPromise,
                    machineRolesPromise,
                    tenantResourceGroupPromises,
                    tagSetsResourceGroupPromises,
                    showOnboarding,
                };
            }}
            renderWhenLoaded={(data) => <InfrastructureDashboard initialData={data} />}
            renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class InfrastructureDashboardInner extends DataBaseComponent<GlobalConnectedProps, InfrastructureDashboardState> {
    private machineIconHelper = new MachineIconHelper();

    constructor(props: GlobalConnectedProps) {
        super(props);
        this.state = {
            isSearching: false,
            filterPartialName: "",
        };
    }

    render() {
        return (
            <InfrastructureLayout {...this.props}>
                <PaperLayout title={Title} busy={this.state.busy} errors={this.errors} sectionControl={<InfraOverviewOnboardingActions environmentsSummaryResourcePromise={this.props.initialData.environmentsSummaryPromise} />}>
                    {this.props.initialData.showOnboarding ? (
                        <Onboarding />
                    ) : (
                        <div className={styles.cardsContainer}>
                            {this.props.initialData.environmentsSummaryPromise && (
                                <InfraOverviewCard<EnvironmentsSummaryResource>
                                    key="environmentsCard"
                                    title="Environments"
                                    getCount={(resource: EnvironmentsSummaryResource) => resource.EnvironmentSummaries.length}
                                    link={routeLinks.infrastructure.environments.root}
                                    dataPromise={this.props.initialData.environmentsSummaryPromise}
                                    hasContent={(resource: EnvironmentsSummaryResource) => resource.EnvironmentSummaries.length > 0}
                                    renderContent={(summaryResource: EnvironmentsSummaryResource) => <EnvironmentsContent summaryResource={summaryResource} numberOfEnvironmentsToRender={maxNumberOfItemsToShowInCards} />}
                                />
                            )}
                            {this.props.initialData.environmentsSummaryPromise && (
                                <InfraOverviewCard<EnvironmentsSummaryResource>
                                    key="machinesCard"
                                    title="Deployment Targets"
                                    link={routeLinks.infrastructure.machines.root}
                                    getCount={(resource: EnvironmentsSummaryResource) => resource.TotalMachines}
                                    dataPromise={this.props.initialData.environmentsSummaryPromise}
                                    hasContent={(resource: EnvironmentsSummaryResource) => resource.TotalMachines > 0}
                                    renderContent={(summaryResource: EnvironmentsSummaryResource) => (
                                        <MachinesContent link={(filter: object) => routeLinks.infrastructure.machines.filtered(filter)} machineIconHelper={this.machineIconHelper} summaryResource={summaryResource} />
                                    )}
                                />
                            )}

                            {this.props.initialData.environmentsSummaryPromise && (
                                <InfraOverviewCard<EnvironmentsSummaryResource>
                                    key="targetHealthStatusCard"
                                    title="Target Status"
                                    link={routeLinks.infrastructure.machines.root}
                                    getCount={(resource: EnvironmentsSummaryResource) => sum(Object.values(resource.MachineHealthStatusSummaries))}
                                    dataPromise={this.props.initialData.environmentsSummaryPromise}
                                    hasContent={(resource: EnvironmentsSummaryResource) => resource.TotalMachines > 0}
                                    renderContent={(summaryResource: EnvironmentsSummaryResource) => (
                                        <HealthStatusContent link={(filter: object) => routeLinks.infrastructure.machines.filtered(filter)} machineIconHelper={this.machineIconHelper} summaryResource={summaryResource} />
                                    )}
                                />
                            )}
                            {this.props.initialData.machineRolesPromise && (
                                <InfraOverviewCard<string[]>
                                    key="rolesCard"
                                    title="Target Roles"
                                    link={routeLinks.infrastructure.machines.root}
                                    getCount={(resource: string[]) => resource.length}
                                    dataPromise={this.props.initialData.machineRolesPromise}
                                    hasContent={(resource: string[]) => resource.length > 0}
                                    renderContent={(summaryResource: string[]) => (
                                        <RolesContent machineRoles={summaryResource} link={(filter: object) => routeLinks.infrastructure.machines.filtered(filter)} numberOfItemsToRender={maxNumberOfItemsToShowInCards} />
                                    )}
                                />
                            )}
                            {this.props.initialData.workerpoolsSummaryPromise && (
                                <InfraOverviewCard<WorkerPoolsSummaryResource>
                                    key="workersCard"
                                    title="Worker Pools"
                                    link={routeLinks.infrastructure.workerPools.root}
                                    getCount={(resource: WorkerPoolsSummaryResource) => resource.WorkerPoolSummaries.length}
                                    dataPromise={this.props.initialData.workerpoolsSummaryPromise}
                                    hasContent={(resource: WorkerPoolsSummaryResource) => resource.WorkerPoolSummaries.length > 0}
                                    renderContent={(summaryResource: WorkerPoolsSummaryResource) => <WorkerPoolsContent summaryResource={summaryResource} numberOfItemsToRender={maxNumberOfItemsToShowInCards} />}
                                />
                            )}
                            {this.props.initialData.workerpoolsSummaryPromise && (
                                <InfraOverviewCard<WorkerPoolsSummaryResource>
                                    key="workersMachinesCard"
                                    title="Workers"
                                    link={routeLinks.infrastructure.workerMachines.root}
                                    getCount={(resource: WorkerPoolsSummaryResource) => sum(Object.values(resource.MachineEndpointSummaries))}
                                    dataPromise={this.props.initialData.workerpoolsSummaryPromise}
                                    hasContent={(resource: WorkerPoolsSummaryResource) => resource.TotalMachines > 0}
                                    renderContent={(summaryResource: WorkerPoolsSummaryResource) => (
                                        <MachinesContent link={(filter: object) => routeLinks.infrastructure.workerMachines.filtered(filter)} machineIconHelper={this.machineIconHelper} summaryResource={summaryResource} />
                                    )}
                                />
                            )}
                            {this.props.initialData.workerpoolsSummaryPromise && (
                                <InfraOverviewCard<WorkerPoolsSummaryResource>
                                    key="workerHealthStatusCard"
                                    title="Worker Status"
                                    link={routeLinks.infrastructure.workerMachines.root}
                                    getCount={(resource: WorkerPoolsSummaryResource) => sum(Object.values(resource.MachineHealthStatusSummaries))}
                                    dataPromise={this.props.initialData.workerpoolsSummaryPromise}
                                    hasContent={(resource: WorkerPoolsSummaryResource) => resource.WorkerPoolSummaries.length > 0}
                                    renderContent={(summaryResource: WorkerPoolsSummaryResource) => (
                                        <HealthStatusContent link={(filter: object) => routeLinks.infrastructure.workerMachines.filtered(filter)} machineIconHelper={this.machineIconHelper} summaryResource={summaryResource} />
                                    )}
                                />
                            )}
                            {this.props.initialData.tagSetsResourceGroupPromises &&
                                this.props.isMultiTenancyEnabled &&
                                isAllowed({
                                    permission: Permission.TenantView,
                                    tenant: "*",
                                }) && (
                                    <InfraOverviewCard<TagSetsResourceGroup>
                                        key="tagSetsCard"
                                        title="Tenant Tag Sets"
                                        link={routeLinks.library.tagSets.root}
                                        getCount={(resource: TagSetsResourceGroup) => resource.tagSetsResources.length}
                                        dataPromise={this.props.initialData.tagSetsResourceGroupPromises}
                                        hasContent={(resource: TagSetsResourceGroup) => resource.tagSetsResources.length > 0}
                                        renderContent={(summaryResource: TagSetsResourceGroup) => (
                                            <TagSetsContent
                                                tagSummaries={summaryResource.environmentsSummaryResource?.MachineTenantTagSummaries}
                                                summaryResource={summaryResource.tagSetsResources}
                                                numberOfItemsToRender={maxNumberOfItemsToShowInCards}
                                            />
                                        )}
                                    />
                                )}
                            {this.props.initialData.tenantResourceGroupPromises &&
                                this.props.isMultiTenancyEnabled &&
                                isAllowed({
                                    permission: Permission.TenantView,
                                    tenant: "*",
                                }) && (
                                    <InfraOverviewCard<TenantResourceGroup>
                                        key="tenantsCard"
                                        title="Tenants"
                                        link={routeLinks.tenants}
                                        getCount={(resource: TenantResourceGroup) => resource.tenantResources?.length}
                                        dataPromise={this.props.initialData.tenantResourceGroupPromises}
                                        hasContent={(resource: TenantResourceGroup) => resource.tenantResources.length > 0}
                                        renderContent={(summaryResource: TenantResourceGroup) => (
                                            <TenantsContent
                                                tenantsResource={summaryResource.tenantResources}
                                                tenantSummaries={summaryResource.environmentsSummaryResource?.MachineEndpointSummaries}
                                                numberOfItemsToRender={maxNumberOfItemsToShowInCards}
                                            />
                                        )}
                                    />
                                )}
                        </div>
                    )}
                </PaperLayout>
            </InfrastructureLayout>
        );
    }
}

const mapGlobalStateToProps = (state: GlobalState, props: InfrastructureDashboardProps): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
        initialData: props.initialData,
    };
};

const InfrastructureDashboard = connect(mapGlobalStateToProps)(InfrastructureDashboardInner);
export default InfrastructureDashboardPage;
